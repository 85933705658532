<template>
  <div>
    <p class="text-2xl mb-6">Liste des roles</p>
    <v-col cols="12" class="d-flex flex-row-reverse">
      <v-btn color="primary" link :to="{ name: 'users-list' }" class="mx-1">
        <v-icon class="mr-3">{{ icons.list }}</v-icon
        >Utilisateurs</v-btn
      >
      <v-btn color="primary" link :to="{ name: 'permissions-list' }" class="mx-1">
        <v-icon class="mr-3">{{ icons.list }}</v-icon
        >Permissions</v-btn
      >
      <v-btn color="primary" link :to="{ name: 'roles-create' }" class="mx-1">
        <v-icon class="mr-3">{{ icons.plus }}</v-icon
        >Créer un nouveau role</v-btn
      >
    </v-col>
    <v-col cols="12"> <roles-table></roles-table> </v-col>
  </div>
</template>

<script>
import { mdiPlusBox as plus, mdiViewListOutline as list } from "@mdi/js";
import RolesTable from "@/components/admin/users/roles/tables/RolesTable.vue";

export default {
  name: "RoleList",
  components: {
    RolesTable,
  },
  created() {},
  data() {
    return {
      icons: {
        plus,
        list
      },
    };
  },
  methods: {},
  watch: {},
};
</script>
<style scoped></style>
